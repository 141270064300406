

import React, { useContext, useRef } from 'react'
import { useState, useEffect } from "react"
import { ArrowLeft, ArrowRightCircle, XLg } from "react-bootstrap-icons"
import UserContext from './Provider';
import Dashboard from './ConfirmDialog';
import LoadingIcons from 'react-loading-icons'
import { toast } from 'react-toastify';

const HOST = "https://fffapifree.cam/backend/"
function Home() {
     
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const { setUserState} = useContext(UserContext); 
    const [currentPackage, setCurentPackage] = useState(null);
    const [info, setInfo] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);

    
    const logout = () => {
        try {
            setUserState({})
            window.data = null
            localStorage.removeItem('user')
           } catch(e){
    
           }
    }
    const onBuy = (type) => {
        setCurentPackage(type)
        setIsShowDialog(true)
     
    }

    const getSubscription = async () => {
        setIsLoading(true)
        var info = await fetch(`${HOST}/subscription`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": window.accessToken
            },
        }).then((res) => {
            return res.json()
        })

        if (info && info.code == "AUTH") {  
            logout()
            toast.error("Session expired, Please login again", {
                position: 'bottom-center',
              });
            
        }

        setInfo(info && info.data && info.data.subscription)
        setSubscriptions( (info && info.data && info.data.pendding) || []  )

        setIsLoading(false)
        console.log(info)
    }

    useEffect(() => {  
        getSubscription()
     }, [])

  return (
    <div className="mini bg-light m-auto shadow-lg p-3 mb-5 bg-white rounded fw-bold " style={{position:"relative"}}>
   
    <div  className=''>
            <div>Your subscription</div>
             <div> {(info && info.day_left) || 0} day(s) left</div> 
             <button 
             onClick={()=>getSubscription()}
             className='button  mt-2'>  {isLoading && <LoadingIcons.TailSpin strokeWidth={2} strokeOpacity={1} stroke="#000000" width={15} height={15} />}Refresh</button>
        </div>
        {
            (subscriptions.length > 0) && <div className='badge bg-warning'>{subscriptions.length} Your registration is being processed</div>
        }
    <hr></hr>
    
    <div
    onClick={(()=>{
      logout()
    })}
     style={{position:'absolute', top: 0, right: 0, padding: 10}}>
        <XLg/>
       
    </div>
    
    <div className=" justify-content-center row">
        
        <div  className='col-sm-4 mb-3'>
            <h2>1Month</h2>
             <code style={{fontSize:15}}>150$/Month</code>
             <div> Unlimited requests/month </div>
             <div> 500 requests/second </div>
             {
                 <button className='button  mt-4' onClick={()=>onBuy(1)}>Buy now</button>
             }
              
        </div>
        <div className='col-sm-4 mb-3' >
        <h2>3Month</h2>
             <code style={{fontSize:15}}>60$/Month</code>
             <div> Unlimited requests/month </div>
             <div> 500 requests/second </div>
            { <button className='button  mt-4' onClick={()=>onBuy(2)}>Buy now</button>}
        </div>
        <div className='col-sm-4 mb-3'  >
          
        <h2>6Month</h2>
             <code style={{fontSize:15}}> 50$/Month</code>
             <div> Unlimited requests/month </div>
             <div> 500 requests/second </div>
             {   <button className='button  mt-4' onClick={()=>onBuy(3)}>Buy now</button>}
        </div>
    </div>
   { isShowDialog && <Dashboard type={currentPackage} Close = {()=> {
         setIsShowDialog(false)
            getSubscription()
   }}/>}
    <div className={"text-secondary"}>Free plan limit access 1 req/second, 50000req/day for all user</div>
   <div className={"text-secondary"}>Contact us via email <a href='mailto: hqapiapp@gmail.com'> hqapiapp@gmail.com</a> to request a demo</div>
  </div>
     
  )
}

export default Home
