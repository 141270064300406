
import { UserProvider } from "./screen/Provider";
import { useState } from "react";
import App from "./App";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";  
function Root() {
  const [userState, setUserState] = useState(null)
  useState(()=>{
    var data = localStorage.getItem('user')
    if (data != null)  {
      let info = JSON.parse(localStorage.getItem('user'));
      if (info != null && info.tokens != null) {
        window.accessToken = "Bearer " + info.tokens.access_token.token 
      }
      setUserState(info)
    }
    
  },[])
 
  return (
    <UserProvider value={{
        user: userState,
        setUserState: (state)=> { 
            setUserState(state)
        }
    }}>
       <ToastContainer />
      <App/>
      
    </UserProvider>
  );
}

export default Root;
