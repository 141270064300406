import React, { useContext, useRef } from 'react'
import { useState, useEffect } from "react"
import { ArrowLeft, ArrowRightCircle } from "react-bootstrap-icons"
import UserContext from './Provider';

const HOST = "https://fffapifree.cam/backend/"
function Login() {
    const [input, setInput] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassowrd, setConfirmPassword] = useState('');
    const [isRegister, setIsRegister] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const passwordRef = useRef(null);
    const confirmRef = useRef(null);

    const [error, setError] = useState(false);

    const {setUserState} = useContext(UserContext)


    const OnNext = (e) => {
        if(input.length < 5) {
            setError('Username must be 5 characters long')
            return
        }

        if (!isRegister && !isLogin) {
            e.preventDefault(); 
            check(input)
        } else {
            console.log("NEXT")
            if (isRegister) {
                if (confirmPassowrd != password) {
                    setError('Password does not match')
                    return
                }
                if (confirmPassowrd.length < 5) {
                    setError('Password must be 5 characters long')
                    return
                }
                register()
            } else {
                if (password.length < 5) {
                    setError('Password must be 5 characters long')
                    return
                }
                login()
            }
        }
    }



    const oninput = () => {
        if (isRegister ||  isLogin) {
            setIsLogin(false)
            setIsRegister(false)
            setPassword('')
            setConfirmPassword('')
            setError('')
        }
    }
  
    const check = async ()=> {
      try {
        setIsLoading(true)
        setError('')
        var  token =  await fetch(HOST+'/auth/check?username='+input).then(res => res.json())
        if (token.code == "OK" && token.data != null) { 
            setIsLogin(true) 
            setIsRegister(false)
            window.accessToken = "Bearer " + token.data.token 
        } else if (token.code == "NOTFOUND") { 
            setIsLogin(false) 
            setIsRegister(true)
            window.accessToken = "Bearer " + token.data.token 
        }  else {
            setError(token.message)
        }
        setTimeout(()=>{
            passwordRef.current?.focus()
        }, 200)
      
      } catch(_) {
        setError("Server Error")
      }
      setIsLoading( false )
    }

    const register = async () => {
        try {
            setIsLoading(true);
            setError('');
            const response = await fetch(HOST+'/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": window.accessToken
                },
                body: JSON.stringify({ 
                    password: password,
                }),
            });
            const data = await response.json();
            if (data && data.code == "OK") { 
                successLogin(data)
            } else if (data && data.code == "AUTH") {  
                oninput()
                setError("Session expired, Please login again")
            } else {
                setError(data && data.message);
            }
           
        } catch (error) {
            setError('Server Error');
        }
        setIsLoading(false);
    };

    const login = async () => {
        try {
            setIsLoading(true);
            setError('');
           
            const response = await fetch(HOST+'/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": window.accessToken
                },
                body: JSON.stringify({ 
                    password: password,
                }),
            });
            const data = await response.json();
            if (data && data.code == "OK") { 
                successLogin(data)
            } else if (data && data.code == "AUTH") {  
                oninput()
                setError("Session expired, Please login again")
            } else {
                setError(data && data.message);
            }
           
        } catch (error) {
            setError('Server Error');
        }
        setIsLoading(false);
    };

    const successLogin = async (data) => { 
        window.data = data.data 
        localStorage.setItem('user', JSON.stringify(data.data))
        window.accessToken = "Bearer " + data.data.tokens.access_token.token
        setUserState(data.data)
    }
  return (
    <div className="mini bg-light m-auto shadow-lg p-3 mb-5 bg-white rounded fw-bold">
    <p className="heading"> Login / Register</p> 
    <div> Please Login or Regiter to continue  </div>
    <div className="d-flex justify-content-center">
      <div className="p-3">
      
      <div>
          <div className=' formClass justify-content-center h2 '> 
              <div className='m-2  d-flex'>
                { (!isRegister && !isLogin) && <div style={{width: 42}}></div>}
                <input type="text" 
                placeholder='Username'
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        OnNext(e)
                    }
                }}
                onChange={(e) => {
                    oninput()
                    setInput(e.target.value); 
                }}  value={input}></input>
                 { (!isRegister && !isLogin) &&  <div>
                <button onClick={OnNext}> <ArrowRightCircle /> </button>
              </div>}
                
              </div>

              {
                isRegister && <div>
                    <div className='mt-4'>
                    <input  
                placeholder='Password'
                type='password'
                ref={passwordRef}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && confirmRef.current != null) {
                        confirmRef.current.focus()
                    }
                }}
                onChange={(e) => {
                    setPassword(e.target.value); 
                }}  value={password}></input>
                </div>
                <div className='mt-4'>
                    <input  
                ref={confirmRef}
                placeholder='Confirm password'
                type='password'
                onKeyDown={(e) => {
                    if (e.key === 'Enter' ) {
                        OnNext(e)
                    }
                }}
                onChange={(e) => {
                      setConfirmPassword(e.target.value); 
                }}  value={confirmPassowrd}></input>
                </div>

                <button onClick={OnNext} className='m-4 p-2 button'>Register</button>
                </div>
              } 
              {
                isLogin && <div>
                    <div className='mt-4'>
                    <input  
                    ref={passwordRef}
                placeholder='Password'
                type='password'
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        OnNext(e)
                    }
                }}
                onChange={(e) => {
                    setPassword(e.target.value); 
                }}  value={password}></input>
                </div> 
                <button onClick={OnNext} className='m-4 p-2 button'>Login</button>
                </div>
              }
              {
                isLoading &&<code style={{fontSize: 12}} className='link-warning'>Loading</code>
              }
              <code style={{fontSize: 14}}>  {error}</code>
          </div> 
     </div>
      </div>
    </div>
  </div>
     
  )
}

export default Login

