import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; 
import Login from "./screen/Login";
import UserContext, { UserProvider } from "./screen/Provider";
import { useContext } from "react";
import Home from "./screen/Dashboard";

function App() {
  const {user} = useContext(UserContext);
 
  return ( 
      <div className="App">
      {
        ((user == null) || user.user == null) &&  <Login/>
      }
    {
         user &&  user.user != null &&  <Home/>
      }
      <p>
        <a href="https://fffapifree.cam" className="link-warning">
        fffapifree.cam
        </a>
      </p>
    </div> 
  );
}

export default App;
