import React, { useRef } from 'react'
import { useState, useEffect } from "react"
import {  CCircle, XLg } from "react-bootstrap-icons"
import LoadingIcons from 'react-loading-icons'
import { toast } from 'react-toastify'
 
const HOST = "https://fffapifree.cam/backend/"
function Dialog(props) {
     const [password, setPassword] = useState('');
   
    const [isLoading, setIsLoading] = useState(false);
    const passwordRef = useRef(null);
 
    const [error, setError] = useState(false);
    const [price, setPrice] = useState(0);

    useEffect(()=> {
        if (props.type == 1) {
            setPrice(150)
        } else if (props.type == 2) {
            setPrice(60 * 3)
        } else if (props.type == 3) {
            setPrice(50 * 6)
        } else {
            setPrice(150)
        }
    },[props.type])

    const OnNext = (e) => {
        if (isLoading) {
            return
        }
        setIsLoading(true);
        submit()
    }
    
    const copy = async (e) => {
        navigator.clipboard.writeText("0x4ea4dDc5A5f16d14FC047529929aA8F02d903e2D")
        toast.success("Copy wallet address", {
            position: 'bottom-center',
          });
    }

    const completed = async (data) => {
        if (password.length < 5) {
            toast.error("Please enter the correct txhash", {
                position: 'bottom-center',
              });
            return
        }
        toast.success("Registration request submitted successfully, your request is being processed", {
            position: 'bottom-center',
          });
        props.Close()
    }

    const submit = async () => {
        try {
            setIsLoading(true);
            setError('');
            const response = await fetch(HOST+'/subscription/request', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": window.accessToken
                },
                body: JSON.stringify({ 
                    package: props.type,
                    transaction_hash: password
                }),
            });
            const data = await response.json();
            if (data && data.code == "OK") { 
                completed(data)
            } else if (data && data.code == "AUTH") {  
                oninput()
                setError("Session expired, Please login again")
            } else {
                setError(data && data.message);
            }
           
        } catch (error) {
            setError('Server Error');
        }
        setIsLoading(false);
    };
    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100%" }}>
            <div className='overlay'  onClick={(() => {
                            props.Close()
                    })}></div>
            <div className=" dialog mini bg-light m-auto shadow-lg p-3 mb-5 bg-white rounded fw-bold" >
                <p className="heading"> Confirm</p>
                You need to transfer
                <div><code style={{ fontSize: 15 }}>{price} BUSD</code></div>
                to wallet address (BEP20)
                <div> <code onClick={copy} className='wallet' style={{ fontSize: 12 }}>0x4ea4dDc5A5f16d14FC047529929aA8F02d903e2D</code></div>
                then enter the <code> transaction hash</code> in the box below and press confirm
              
                <div
                    onClick={(() => {
                            props.Close()
                    })}
                    style={{ position: 'absolute', top: 0, right: 0, padding: 10, cursor: 'pointer' }}>
                    <XLg />
                </div>
                <div>
                    <div>
                        <input
                            disabled={isLoading}
                            style={{ width: '100%' , marginTop: 10, marginBottom:26 }}
                            placeholder='txHash'
                            type='text'
                            ref={passwordRef}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    OnNext(e)
                                }
                            }}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }} value={password}></input>
                    </div>
                    <button onClick={OnNext} className='p-2 button'>
                        {isLoading && <LoadingIcons.TailSpin strokeWidth={2} strokeOpacity={1} stroke="#000000" width={15} height={15} />}
                        Confirm</button>
                        <div>
                <code>{error}</code>
                </div>
                </div>
            </div>
        </div>

    )
}

export default Dialog
